import { forwardRef } from 'react';
import { Small } from '../Typography';
import TextStack from '../TextStack';
import Action from '../Action';
import Text from '@/elements/Text';
import { mapUrl } from '@/routes/Agency/Customer/AddressList';

interface ViewAddressProps {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

const ViewAddress = forwardRef(
  ({ addressLine1, addressLine2, city, state, zip }: ViewAddressProps) => {
    return (
      <div style={{ marginBottom: '10px' }}>
        <Small color="light" style={{ fontStyle: 'italic' }}>
          Current:
        </Small>
        <TextStack spacing="extra-loose">
          {addressLine1?.length && addressLine1.length > 0 ? (
            <Action.Button
              action={{
                a11yLabel: `Open new tab to ${mapUrl({ addressLine1, city, state, zip })}`,
                label: addressLine1!,
                href: mapUrl({ addressLine1, city, state, zip }),
                external: true,
              }}
            />
          ) : (
            <span> No previous address</span>
          )}
          {addressLine2 && <Text>{addressLine2}</Text>}
          {state && city && zip !== '' && (
            <Text>
              {city}, {state} {zip}
            </Text>
          )}
        </TextStack>
      </div>
    );
  },
);

export default ViewAddress;
