import { useReactiveVar } from '@apollo/client';
import { SetStateAction, useState } from 'react';

import { SubmitErrorAlert } from '@/components/Alerts';
import BillingWeekFormField from '@/components/BillingWeekFormField';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModalV2';
import GroupInvoicesFormField from '@/components/GroupInvoicesFormField';
import InvoiceDurationFormField from '@/components/InvoiceDurationFormField';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import MobileTooltipInfo from '@/components/MobileTooltipInfo';

import {
  CONSOLIDATE_CHARGES,
  SEPARATE_INVOICE_BY_MONTH,
  SEPARATE_INVOICE_HINT,
  SWEEP_HINT,
  UNKNOWN_ERROR_TEXT,
  SEND_INVOICE_EMAILS,
  SEND_INVOICE_EMAILS_HINT,
  DISABLE_INVOICE_EMAILS_TITLE,
  DISABLE_INVOICE_EMAILS_MESSAGE,
  DISABLE_INVOICE_EMAILS_SUB_MESSAGE,
} from '@/constants/text';
import { useUpdateAccountCutOffMutation } from '@/graphql';
import { Role } from '@/routes/PrivateRoute';
import {
  BillingWeekEnum,
  GetAccountQuery,
  InvoiceCutOffEnum,
  InvoiceGroupByEnum,
  Scalars,
} from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import { useModal } from 'react-modal-hook';
import styled from '@/styles';

interface ChageInvoiceDurationModalProps {
  account: GetAccountQuery['account'];
  onClose: () => Scalars['Void'];
}

const Actions = styled(Stack, {
  marginTop: '18px',
});

const ChangeInvoiceDurationModal = ({
  account,
  onClose,
}: ChageInvoiceDurationModalProps) => {
  const [
    updateAccountCutOff,
    { loading: updateAccountCutOffIsLoading, error: updateAccountCutOffError },
  ] = useUpdateAccountCutOffMutation({
    onCompleted: onClose,
    update: (cache) => {
      cache.modify({
        id: cache.identify(account),
        fields: {
          cutOff() {},
          billingWeek() {},
          monthlyCutOff() {},
          sendInvoiceEmails() {},
          consolidateUnprocessedCharges() {},
          groupBy() {},
        },
      });
    },
  });
  const [seperateInvoices, setSeperateInvoices] = useState(
    account.monthlyCutOff,
  );
  const [consolidateCharges, setConsolidateCharges] = useState<boolean>(
    account.consolidateUnprocessedCharges === null
      ? false
      : account.consolidateUnprocessedCharges,
  );
  const [sendInvoiceEmails, setSendInvoiceEmails] = useState<boolean>(
    account.sendInvoiceEmails === null ? false : account.sendInvoiceEmails,
  );
  const [groupBy, setGroupBy] = useState(
    account.groupBy || InvoiceGroupByEnum.NONE,
  );

  const currentAdmin = useReactiveVar(currentAdminVar);
  const isTenantAdmin = currentAdmin?.role === Role.TENANT_ADMIN;

  const [invoiceDuration, setInvoiceDuration] = useState<
    GetAccountQuery['account']['cutOff']
  >(account.cutOff);
  const isDurationWeekly = invoiceDuration === InvoiceCutOffEnum.WEEKLY;

  const [billingWeek, selectedBillingWeek] = useState<
    GetAccountQuery['account']['billingWeek']
  >(account.billingWeek);
  const handleOnChangeBillingWeek = (
    billingWeek: SetStateAction<GetAccountQuery['account']['billingWeek']>,
  ) => {
    selectedBillingWeek(billingWeek);
  };

  const handleOnChangeInvoiceDuration = (
    invoiceDuration: SetStateAction<GetAccountQuery['account']['cutOff']>,
  ) => {
    setInvoiceDuration(invoiceDuration);
    if (invoiceDuration !== InvoiceCutOffEnum.WEEKLY) {
      // set default values
      setGroupBy(InvoiceGroupByEnum.NONE);
      setConsolidateCharges(true);
      selectedBillingWeek(BillingWeekEnum.MONDAY);
    }
  };

  const handleSendInvoiceEmailsChange = (checked: boolean) => {
    if (!!sendInvoiceEmails && !checked) {
      showConfirmationModal();
    } else {
      setSendInvoiceEmails(checked);
    }
  };

  const handleOnSaveInvoiceDuration = () => {
    updateAccountCutOff({
      variables: {
        accountId: account.id,
        invoiceCutOff: invoiceDuration,
        monthlyCutOff: seperateInvoices,
        billingWeek,
        consolidateUnprocessedCharges: consolidateCharges,
        groupBy,
        sendInvoiceEmails: sendInvoiceEmails,
      },
    });
  };

  const [showConfirmationModal, hideConfirmationModal] = useModal(
    () => (
      <ConfirmationModal
        title={DISABLE_INVOICE_EMAILS_TITLE}
        message={DISABLE_INVOICE_EMAILS_MESSAGE}
        subMessage={DISABLE_INVOICE_EMAILS_SUB_MESSAGE}
        onConfirm={(e) => {
          e.stopPropagation();
          hideConfirmationModal();
          setSendInvoiceEmails(false);
        }}
        onCancel={(e) => {
          e.stopPropagation();
          hideConfirmationModal();
        }}
        confirmLabel="Yes, Disable"
        cancelLabel="Cancel"
      />
    ),
    [],
  );

  return (
    <Modal
      size="xs"
      title="Invoice Settings"
      onRequestClose={onClose}
      wrapperBackground={true}
    >
      <Card>
        <Card.Section>
          {updateAccountCutOffError && (
            <SubmitErrorAlert description={UNKNOWN_ERROR_TEXT} />
          )}
          <InvoiceDurationFormField
            invoiceDuration={invoiceDuration}
            onChangeInvoiceDuration={handleOnChangeInvoiceDuration}
          />
          {isTenantAdmin && isDurationWeekly && (
            <>
              <BillingWeekFormField
                billingWeek={billingWeek}
                onChangeBillingWeek={handleOnChangeBillingWeek}
              />
              <GroupInvoicesFormField
                groupBy={groupBy}
                onChangeGroupBy={(val) => setGroupBy(val)}
              />
            </>
          )}

          <Stack vertical gap={10}>
            {isTenantAdmin && (
              <Option
                appearance="switch"
                checked={sendInvoiceEmails}
                id="send-invoice-emails"
                label={
                  <>
                    <span style={{ marginRight: '10px' }}>
                      {SEND_INVOICE_EMAILS}
                    </span>
                  </>
                }
                tooltip={
                  <MobileTooltipInfo
                    text={SEND_INVOICE_EMAILS_HINT}
                    title="Send invoices via email Detail"
                  />
                }
                onChange={(val) =>
                  handleSendInvoiceEmailsChange(val.target.checked)
                }
              />
            )}
            {isDurationWeekly && isTenantAdmin && (
              <Option
                appearance="switch"
                checked={consolidateCharges}
                id="consolidate-charges"
                label={
                  <>
                    <span style={{ marginRight: '10px' }}>
                      {CONSOLIDATE_CHARGES}
                    </span>
                  </>
                }
                tooltip={
                  <MobileTooltipInfo
                    text={SWEEP_HINT}
                    title="Consolidate charges Detail"
                  />
                }
                onChange={(val) => setConsolidateCharges(val.target.checked)}
              />
            )}
            <Option
              appearance="switch"
              checked={seperateInvoices}
              id="invoice-seperate-month"
              label={
                <>
                  <span style={{ marginRight: '10px' }}>
                    {SEPARATE_INVOICE_BY_MONTH}
                  </span>
                </>
              }
              tooltip={
                <MobileTooltipInfo
                  text={SEPARATE_INVOICE_HINT}
                  title="Separate invoices by month Detail"
                />
              }
              onChange={(val) => setSeperateInvoices(val.target.checked)}
            />
          </Stack>
          <Actions justify="end" gap={10}>
            <Button
              a11yLabel="Cancel"
              appearance="outline"
              label="Cancel"
              onClick={onClose}
            />
            <Button
              a11yLabel="Save Invoice Duration"
              isLoading={updateAccountCutOffIsLoading}
              label="Save"
              onClick={handleOnSaveInvoiceDuration}
            />
          </Actions>
        </Card.Section>
      </Card>
    </Modal>
  );
};

export default ChangeInvoiceDurationModal;
