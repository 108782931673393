import { useReactiveVar } from '@apollo/client';
import { useModal } from 'react-modal-hook';
import { useMemo } from 'react';

import ChangeInvoiceDurationModal from './ChangeInvoiceDurationModal';

import Card from '@/components/Card';
import { Role } from '@/routes/PrivateRoute';
import { GenericActionOrList, Maybe } from '@/types';
import { GetAccountQuery, InvoiceCutOffEnum } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import StatusBadge from '@/components/StatusBadge';

interface InvoiceDurationProps {
  account: GetAccountQuery['account'];
  phoneOnly?: boolean;
}

enum BillingWeekEnum {
  FRIDAY = 'Friday',
  MONDAY = 'Monday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
  THURSDAY = 'Thursday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
}

const mapInvoiceDuration = (
  invoiceDuration: Maybe<InvoiceCutOffEnum>,
): string => {
  if (!invoiceDuration) {
    return 'N/A';
  }

  return {
    [InvoiceCutOffEnum.BIWEEKLY]: 'Byweekly',
    [InvoiceCutOffEnum.MONTHLY]: 'Monthly',
    [InvoiceCutOffEnum.WEEKLY]: 'Weekly',
    [InvoiceCutOffEnum.DAILY]: 'Daily',
    [InvoiceCutOffEnum.JOB]: 'By Job',
  }[invoiceDuration];
};

const InvoiceDuration = ({
  account,
  phoneOnly = false,
}: InvoiceDurationProps) => {
  const currentAdmin = useReactiveVar(currentAdminVar);

  const [showChangeInvoiceDurationModal, hideShowChangeInvoiceDurationModal] =
    useModal(() => {
      return (
        <ChangeInvoiceDurationModal
          account={account}
          onClose={hideShowChangeInvoiceDurationModal}
        />
      );
    }, [account]);

  const cardActions = useMemo<GenericActionOrList[]>(() => {
    return [
      {
        a11yLabel: 'Change Invoice Duration',
        label: 'Change',
        onAction: showChangeInvoiceDurationModal,
        id: 'btn-change-invoice-duration',
      },
    ];
  }, []);
  const isDurationWeekly = account.cutOff === InvoiceCutOffEnum.WEEKLY;
  const isTenantAdmin = currentAdmin!.role === Role.TENANT_ADMIN;

  return (
    <Card
      actions={cardActions}
      noRadius={phoneOnly}
      title="Invoice Preferences"
    >
      <Card.Section
        title={'Invoice Cycle'}
        titleStyle={{ paddingBottom: '0px' }}
      >
        {mapInvoiceDuration(account.cutOff)}
      </Card.Section>
      {isTenantAdmin && account.cutOff === InvoiceCutOffEnum.WEEKLY && (
        <Card.Section
          borderNone={true}
          css={{ marginBottom: '20px' }}
          title={'Billing week'}
          titleStyle={{ paddingBottom: '0px' }}
        >
          {account.billingWeek &&
            `Week Starts from ` + BillingWeekEnum[account.billingWeek]}
        </Card.Section>
      )}

      {isDurationWeekly && isTenantAdmin && (
        <Card.Section
          borderNone={true}
          css={{ marginBottom: '20px', textTransform: 'capitalize' }}
          title={'Group By'}
          titleStyle={{ paddingBottom: '0px' }}
        >
          {account.groupBy?.toLowerCase()}
        </Card.Section>
      )}

      {isTenantAdmin && (
        <Card.Section
          borderNone={true}
          css={{ marginBottom: '20px' }}
          title={'Send Invoice Emails'}
          titleStyle={{ paddingBottom: '0px' }}
        >
          {account.sendInvoiceEmails ? (
            'Enabled'
          ) : (
            <StatusBadge label="Disabled" variant="disabled" />
          )}
        </Card.Section>
      )}

      {isDurationWeekly && isTenantAdmin && (
        <Card.Section
          borderNone={true}
          css={{ marginBottom: '20px' }}
          title={'Look back'}
          titleStyle={{ paddingBottom: '0px' }}
        >
          {account.consolidateUnprocessedCharges ? 'Enabled' : 'Disabled'}
        </Card.Section>
      )}

      <Card.Section
        borderNone={true}
        style={{ marginBottom: '20px' }}
        title={'Cross-Month Invoice Break'}
        titleStyle={{ paddingBottom: '0px' }}
      >
        {account.monthlyCutOff ? 'Enabled' : 'Disabled'}
      </Card.Section>
    </Card>
  );
};

export default InvoiceDuration;
