import { IconButton, Checkbox } from '@mui/material';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import Stack from '@/components/Stack';
import StaffingShiftsCardsSection from '../Common/StaffingShiftsCardsSection';

interface ShiftsSectionProps {
  containerRef: any;
  isShowNavIcon: boolean;
  phoneOnly: boolean;
  disabledIcon: string;
  allSelected: boolean;
  selectedSchedules: number[];
  selectedCount: number;
  schedules: any[];
  isClientAdmin: boolean;
  isLTA: boolean;
  onShiftCardSelection: (id: number) => void;
  onSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNavigate: (rightNav?: boolean) => void;
}

export const ShiftsSection = ({
  containerRef,
  isShowNavIcon,
  phoneOnly,
  disabledIcon,
  allSelected,
  selectedSchedules,
  selectedCount,
  schedules,
  isClientAdmin,
  isLTA,
  onShiftCardSelection,
  onSelectAll,
  onNavigate,
}: ShiftsSectionProps) => {
  return (
    <div
      ref={containerRef}
      className="hide-scroll"
      style={{
        width: isShowNavIcon ? '97%' : '100%',
        position: phoneOnly ? 'initial' : 'revert',
        overflow: phoneOnly ? 'auto hidden' : 'hidden',
      }}
    >
      <div>
        <Checkbox
          checked={allSelected}
          indeterminate={selectedSchedules.length > 0 && !allSelected}
          onChange={onSelectAll}
        />
        <span style={{ top: '1px' }}>
          {selectedSchedules.length > 0 ? 'Deselect All' : 'Select All'}
        </span>
        {selectedCount > 0 && (
          <span
            style={{
              marginLeft: '20px',
              backgroundColor: '#EAF1FF',
              padding: '10px',
              borderRadius: '20px',
            }}
          >
            {allSelected ? 'All ' : ''}
            {selectedCount} shifts selected
          </span>
        )}
      </div>

      <Stack
        className="hide-scroll"
        css={{
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
          flexDirection: 'row',
        }}
        gap={20}
      >
        {schedules.map((sch, idx) => (
          <StaffingShiftsCardsSection
            key={idx}
            isClientAdmin={isClientAdmin}
            isLTA={isLTA}
            schedule={sch}
            selectedSchedules={selectedSchedules}
            onShiftCardSelection={onShiftCardSelection}
          />
        ))}
      </Stack>

      {!phoneOnly && isShowNavIcon && (
        <>
          <IconButton
            disabled={disabledIcon === 'right'}
            sx={{
              position: 'absolute',
              top: '160px',
              right: '1%',
              border: '1px solid #D3D3D3',
              borderRadius: '20px',
              cursor: 'pointer',
              padding: '0px',
            }}
            onClick={() => onNavigate(true)}
          >
            <NavigateNext />
          </IconButton>
          <IconButton
            disabled={disabledIcon === 'left'}
            sx={{
              position: 'absolute',
              top: '160px',
              left: '1%',
              border: '1px solid #D3D3D3',
              borderRadius: '20px',
              cursor: 'pointer',
              padding: '0px',
            }}
            onClick={() => onNavigate(false)}
          >
            <NavigateBefore />
          </IconButton>
        </>
      )}
    </div>
  );
};
