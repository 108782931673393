const UNKNOWN_ERROR_TEXT =
  'An unknown error has ocurred, please contact support.';

const NOT_PROVIDED = 'Not provided';

const MANDATE_TEXT = `By clicking [accept], you authorize GravyWork to debit the bank account specified 
                      in 'Payment Method' for any charges arising from your use of GravyWork's services, 
                      in accordance with GravyWork's terms and conditions. If you have any questions or 
                      concerns about this authorization, please review GravyWork's terms and conditions or 
                      contact our customer service for more information.`;

const MAX_LENGTH_ADDRESS_INSTRUCTIONS = 500;
const MAX_LENGTH_SHIFT_SUPERVISOR_INSTRUCTIONS = 500;
const MAX_LENGTH_UNIFORM_INSTRUCTIONS = 500;
const MAX_LENGTH_JOB_INSTRUCTIONS = 700;

const INVOICE_DURATION_HINT =
  "Invoice Cycle specifies how jobs will be separated for your invoices. For example, if 'Daily' is selected, you will receive your invoices on a weekly basis but they will be separated by jobs completed per day.";
const BILLING_WEEK_HINT =
  "Billing Week sets the start and end days for the client's work week. Monday to Sunday is the default option.";
const GROUP_BY_HINT =
  'Group By groups your weekly invoices by skill or location';
const SWEEP_HINT =
  "Sweep ensures to include approved timesheets from previous weeks in this week's invoice if they have not been invoiced yet.";
const SEPARATE_INVOICE_HINT =
  'Separate invoices by month ensures that services crossing from one month to the next will be billing in two separate invoices';
const SEPARATE_INVOICE_BY_MONTH = 'Separate invoices by month';
const CONSOLIDATE_CHARGES = 'Consolidate all unprocessed charges';
const SEND_INVOICE_EMAILS = 'Send invoice via emails';
const SEND_INVOICE_EMAILS_HINT =
  "Enable this setting to automatically send invoices to the client via email. If disabled, invoices will still be generated and tracked in the system but won't be sent via email.";
const DISABLE_INVOICE_EMAILS_TITLE = 'Disable Invoice Email';
const DISABLE_INVOICE_EMAILS_MESSAGE =
  'Are you sure you want to disable invoice emails for this client?';
const DISABLE_INVOICE_EMAILS_SUB_MESSAGE =
  'This client will no longer receive invoices via email. Invoices will still be generated and tracked in the system.';

export {
  UNKNOWN_ERROR_TEXT,
  NOT_PROVIDED,
  MANDATE_TEXT,
  MAX_LENGTH_ADDRESS_INSTRUCTIONS,
  MAX_LENGTH_SHIFT_SUPERVISOR_INSTRUCTIONS,
  MAX_LENGTH_UNIFORM_INSTRUCTIONS,
  MAX_LENGTH_JOB_INSTRUCTIONS,
  INVOICE_DURATION_HINT,
  BILLING_WEEK_HINT,
  GROUP_BY_HINT,
  SWEEP_HINT,
  SEPARATE_INVOICE_BY_MONTH,
  SEPARATE_INVOICE_HINT,
  CONSOLIDATE_CHARGES,
  SEND_INVOICE_EMAILS,
  SEND_INVOICE_EMAILS_HINT,
  DISABLE_INVOICE_EMAILS_TITLE,
  DISABLE_INVOICE_EMAILS_MESSAGE,
  DISABLE_INVOICE_EMAILS_SUB_MESSAGE,
};
