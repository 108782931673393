import { useState, useMemo } from 'react';

export const useScheduleSelection = (schedules: any[]) => {
  const isFullyStaffed = (schedule: any) => {
    const hiredWorkersList = schedule.hiredWorkers || [];
    return hiredWorkersList.length > 0
      ? hiredWorkersList.length === schedule?.quantity
      : false;
  };

  const [selectedSchedules, setSelectedSchedules] = useState<number[]>(() => {
    return schedules
      .filter((sch) => !isFullyStaffed(sch))
      .map((sch) => sch.groupId);
  });

  const allSelected = useMemo(() => {
    return selectedSchedules.length === schedules.length;
  }, [selectedSchedules, schedules]);

  const handleSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (selectedSchedules.length > 0) {
        setSelectedSchedules([]);
      } else {
        const selectableSchedules = schedules.filter(
          (sch) => !isFullyStaffed(sch),
        );
        setSelectedSchedules(selectableSchedules.map((sch) => sch.groupId));
      }
    } else {
      setSelectedSchedules([]);
    }
  };

  const handleShiftCardSelection = (scheduleId: number) => {
    const idx = selectedSchedules.indexOf(scheduleId);
    if (idx === -1) {
      setSelectedSchedules([...selectedSchedules, scheduleId]);
    } else {
      setSelectedSchedules(selectedSchedules.filter((id) => id !== scheduleId));
    }
  };

  return {
    selectedSchedules,
    setSelectedSchedules,
    allSelected,
    handleSelectAllClick,
    handleShiftCardSelection,
  };
};
