import { faEllipsisV, faPrint } from '@fortawesome/free-solid-svg-icons';
import {
  CheckBoxOutlined,
  CloseOutlined,
  IndeterminateCheckBoxOutlined,
  MoreVert,
} from '@mui/icons-material';
import { Checkbox, Dialog, DialogContent, IconButton } from '@mui/material';
import { usePDF } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { ReactNode, useMemo, useEffect, useState } from 'react';
import * as React from 'react';

import { disableCheckbox } from '../../Order/util';
import PrintWorkerTimeSheet from '../PrintWorkerTimeSheet';
import { JobStatus, enableCheckboxForClient } from '../util';
import { GAEvent } from '@/constants/gaevents';
import useAnalytics from '@/util/analytics';
import ActionTab from './ActionsTab';
import HiredWorkerRow from './HiredWorkerRow';
import CustomTooltipJob from './TooltipJob';
import WorkerMobileDisableView from './WorkerMobileDisableView';
import {
  DeclinedChip,
  DroppedChip,
  InvitedChip,
  RemovedWorkerChip,
  RenderWorkerInfo,
} from './workerUtils';

import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import Table from '@/components/Table';
import Icon from '@/elements/Icon';
import { TableRow, TableCell } from '@/elements/Table';
import Text from '@/elements/Text';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  GetJobQuery,
  JobTypeEnum,
  RelationshipKindEnum,
  TimesheetStatusEnum,
} from '@/types/graphql';
import {
  checkIfJobIsActiveByStatus,
  getDroppedWorkersByJobWorkers,
  getHiredWorkersByJobWorkers,
  getRemovedWorkersByJobWorkers,
  sortDroppedWorkersByDropDate,
} from '@/util/job';
import './index.css';
import Button from '@/components/Button';
import WorkerStaffingModel from '@/components/WorkerStaffingModel';
import Stack from '@/components/Stack';
import SelectAllIcon from '@/assets/icons/SelectAllIcon';

import {
  HeaderDialog,
  MenuItemRight,
  MenuList,
  ProfileCard,
  ProfileCardWrap,
  TitleDialog,
  CountPill,
  IconButtonMenu,
} from './style';

import { PrintIcon, CrossHairIcon } from '@/assets/icons';
import styled from '@/styles';
import { isWeb } from '@/util/platform';
import PermissionComponent from '@/routes/PermissionComponent';
import {
  useCreateCustomerWorkerRelationshipMutation,
  useRemoveCustomerWorkerRelationshipMutation,
  WorkerRelationshipItemFragmentDoc,
} from '@/graphql';

const PrintButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
});

type Props = {
  job: GetJobQuery['job'];
  jobStatus: JobStatus;
  refetchJob: () => void;
};
type WorkerInfo = GetJobQuery['job']['jobWorkers'][0];

const DisableRow = ({
  invited,
  StatusChip,
  isClientAdmin,
  isDropped,
  isNoShow,
  workerStatus,
  job,
  relationshipStatus,
  createWorkerRelationship,
  deleteWorkerRelationship,
}: {
  invited: any;
  StatusChip: ReactNode;
  isClientAdmin: boolean;
  isDropped: boolean;
  isNoShow: boolean;
  job?: GetJobQuery['job'];
  workerStatus:
    | 'NoShow'
    | 'Dropped'
    | 'Removed'
    | 'Invited'
    | 'Declined'
    | 'Pending';
  relationshipStatus: RelationshipKindEnum | null;
  createWorkerRelationship: (
    reason: string,
    comments: string,
    kind: RelationshipKindEnum,
    triggeredFrom: string,
  ) => void;
  deleteWorkerRelationship: (
    kind: RelationshipKindEnum,
    triggeredFrom: string,
  ) => void;
}) => {
  const reason = invited?.dismissReason || invited.dropReason || undefined;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    reason && setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    reason && setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const getName = (worker, isDropped) => {
    let fullName = '';
    const user = isDropped ? worker.worker.user : worker.dismissedBy;
    fullName += user.firstName;
    if (user.middleName !== null && user.middleName !== undefined) {
      fullName += ` ${user.middleName}`;
    }
    fullName += ` ${user.lastName}`;
    return fullName;
  };

  const getBackground = () => {
    switch (workerStatus) {
      case 'Dropped':
      case 'Pending':
        return 'linear-gradient(270deg, #FFF7E1 -78.65%, #FFF 75.74%)';
      case 'Declined':
      case 'NoShow':
        return 'linear-gradient(270deg, #FFEDED -78.65%, #FFF 75.74%)';
      case 'Removed':
        return 'linear-gradient(270deg, #F4F4F4 -78.65%, #FFF 75.74%)';
      default:
        return 'linear-gradient(270deg, #EFFFED -78.65%, #FFF 75.74%)';
    }
  };

  return (
    <>
      {phoneOnly ? (
        <WorkerMobileDisableView
          isClientAdmin={isClientAdmin}
          job={job}
          menuItems={[]}
          workerInfo={invited.worker}
          workerStatus={workerStatus}
          workerStatusChip={StatusChip}
          relationshipStatus={relationshipStatus}
          createWorkerRelationship={createWorkerRelationship}
          deleteWorkerRelationship={deleteWorkerRelationship}
        />
      ) : (
        <>
          <TableRow
            key={invited.id}
            aria-haspopup="true"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            css={{
              background: getBackground(),
              backgroundColor: 'white',
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <TableCell>
              <Checkbox disabled={true} size="small" style={{ padding: 0 }} />
            </TableCell>
            <TableCell colSpan={2} style={{ width: '100%' }}>
              {
                <RenderWorkerInfo
                  StatusChip={StatusChip}
                  isClientAdmin={isClientAdmin}
                  relationshipStatus={relationshipStatus}
                  job={job}
                  createWorkerRelationship={createWorkerRelationship}
                  deleteWorkerRelationship={deleteWorkerRelationship}
                  timesheet={undefined}
                  workerInfo={invited.worker}
                />
              }
            </TableCell>
            <TableCell>
              <IconButton disabled={true}>
                <MoreVert />
              </IconButton>
            </TableCell>
          </TableRow>
          {reason && (
            <CustomTooltipJob
              anchorEl={anchorEl}
              eventDate={
                isDropped
                  ? invited.droppedAt
                  : invited.dismissedAt || invited.rejectedAt
              }
              eventDoneBy={getName(invited, isDropped)}
              handlePopoverClose={handlePopoverClose}
              isDropped={isDropped}
              isNoShow={isNoShow}
              open={open}
              reason={
                isDropped ? invited.dropReason : (invited.dismissReason ?? '')
              }
            />
          )}
        </>
      )}
    </>
  );
};

const WorkerTable = ({ job, jobStatus, refetchJob }: Props) => {
  const jobWorkers = job.jobWorkers;
  const jobInvites = job.jobOffers;
  const isJobActive = checkIfJobIsActiveByStatus(jobStatus);
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [showStaffingModel, setShowStaffingModel] = useState(false);

  const [checkboxIds, setCheckboxIds] = useState<string[]>([]);
  const { currentAdminIsCustomerAdmin: isClientAdmin } = useAuth();

  const showHireOption = () => {
    if (job.jobType === JobTypeEnum.LTA && !job.account.customer.ltaAllowed) {
      return false;
    } else if (!isClientAdmin) {
      return !disableCheckbox(jobStatus, job.firstShiftStartAt, job);
    } else {
      return isJobActive;
    }
  };
  const [sortTimesheets, setSortTimesheets] = useState(true);

  const droppedWorkers = useMemo(() => {
    const droppedWorkersArray = getDroppedWorkersByJobWorkers(jobWorkers);
    const sortedDroppedWorkers =
      sortDroppedWorkersByDropDate(droppedWorkersArray);
    return sortedDroppedWorkers;
  }, [jobWorkers]);

  const { removedWorkers, containsTimesheet } = useMemo(() => {
    const removedWorkers = getRemovedWorkersByJobWorkers(jobWorkers);
    const containsTimesheet = !!removedWorkers?.find((r) =>
      job.timesheets.find((t) => t.jobWorker.id === r.id),
    );

    return { removedWorkers, containsTimesheet };
  }, [jobWorkers]);

  const hiredWorkers = useMemo(() => {
    const hired = getHiredWorkersByJobWorkers(jobWorkers);
    // SORTING
    const notimesheet: WorkerInfo[] = [];
    const incomplete: WorkerInfo[] = [];
    const pending: WorkerInfo[] = [];
    const approved: WorkerInfo[] = [];
    const rejected: WorkerInfo[] = [];
    const noshow: WorkerInfo[] = [];

    hired.forEach((h) => {
      const timesheet = job.timesheets.find(
        (timesheet) => timesheet.jobWorker.id === h.id,
      );
      if (!h.showed) {
        noshow.push(h);
      } else if (!timesheet) {
        notimesheet.push(h);
      } else if (timesheet.status === TimesheetStatusEnum.IN_PROGRESS) {
        const containcheckout =
          timesheet.approvedCheckoutAt || timesheet.reportedCheckoutAt;
        if (sortTimesheets) {
          containcheckout ? pending.push(h) : incomplete.push(h);
        } else {
          notimesheet.push(h);
        }
      } else if (timesheet.status === TimesheetStatusEnum.PENDING) {
        pending.push(h);
      } else if (timesheet.status === TimesheetStatusEnum.APPROVED) {
        approved.push(h);
      } else if (timesheet.status === TimesheetStatusEnum.REJECTED) {
        rejected.push(h);
      }
    });
    setSortTimesheets(false);

    return [
      ...notimesheet,
      ...incomplete,
      ...pending,
      ...approved,
      ...rejected,
      ...noshow,
    ];
  }, [jobWorkers, sortTimesheets]);

  const disableSelectAllForClient = useMemo(() => {
    if (isClientAdmin && hiredWorkers.length) {
      for (let i = 0; i < hiredWorkers.length; i++) {
        if (!hiredWorkers[i].showed) continue;
        const ts = job.timesheets.find(
          (t) => t.jobWorker.id === hiredWorkers[i].id,
        );
        if (enableCheckboxForClient(ts, jobStatus)) return false;
      }
      return true;
    }
    return false;
  }, [hiredWorkers, isClientAdmin, jobStatus]);

  const [instance, updateInstance] = usePDF({
    document: PrintWorkerTimeSheet({ job, hiredWorkers }),
  });

  useEffect(() => {
    updateInstance(PrintWorkerTimeSheet({ job, hiredWorkers }));
  }, [job, hiredWorkers]);

  const { pendingInv, declinedInv } = useMemo(() => {
    const pendingInv: any[] = [];
    const declinedInv: any[] = [];
    jobInvites
      .filter((item) => item.acceptedAt == null)
      .forEach((inv) => {
        if (inv.rejectedAt) {
          declinedInv.push({
            ...inv,
            dismissReason:
              inv.dismissReason || inv.rejectedAt ? 'Rejected' : '',
            dismissedBy: inv.dismissedBy || inv.worker?.user,
          });
        } else {
          pendingInv.push(inv);
        }
      });
    return { pendingInv, declinedInv };
  }, [jobInvites]);

  const getSelectableWorkers = () => {
    const ids: string[] = [];
    hiredWorkers.forEach((jw) => {
      const isNoShow = !jw.showed;
      if (isNoShow) return;
      if (isClientAdmin) {
        const ts = job.timesheets.find((t) => t.jobWorker.id === jw.id);
        if (enableCheckboxForClient(ts, jobStatus)) {
          ids.push(jw.id);
        }
      } else if (jobStatus !== JobStatus.COMPLETED) {
        const ts = job.timesheets.find((t) => t.jobWorker.id === jw.id);
        if (ts) {
          ids.push(jw.id);
        }
      } else if (!isNoShow) {
        ids.push(jw.id);
      }
    });
    return ids;
  };

  const handleSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckboxIds(getSelectableWorkers());
    } else {
      setCheckboxIds([]);
    }
  };

  const allSelected = useMemo(() => {
    if (checkboxIds.length) {
      return checkboxIds.length === jobWorkers.length + jobInvites.length;
    }
    return false;
  }, [checkboxIds, jobWorkers, jobInvites]);

  useEffect(() => {
    setIsSelecting(checkboxIds.length > 0);
  }, [checkboxIds]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const selectAllDisabled = () => {
    return (
      (jobStatus === JobStatus.UPCOMING && job.timesheets.length === 0) ||
      jobStatus === JobStatus.CANCELLED ||
      getSelectableWorkers().length === 0 ||
      disableSelectAllForClient
    );
  };

  const { logEvent } = useAnalytics();

  const [deleteRelationship] = useRemoveCustomerWorkerRelationshipMutation();
  const [createRelationship] = useCreateCustomerWorkerRelationshipMutation();

  const deleteWorkerRelationship = async (
    worker,
    kind = RelationshipKindEnum.FAVORITED,
    triggeredFrom: string = '',
  ) => {
    try {
      const _workerRelationship = job.account.customer.workerRelationships.find(
        (r) => r.worker.id === worker.id && r.kind === kind,
      );
      await deleteRelationship({
        variables: {
          customerId: job.account.customer.id,
          workerId: worker.id,
          kind: kind,
        },
        update: (cache) => {
          cache.modify({
            id: `Customer:${job.account.customer.id}`,
            fields: {
              workerRelationships(existingRefs = [], { readField }) {
                return existingRefs.filter(
                  (relationshipRef) =>
                    _workerRelationship?.id !==
                    readField('id', relationshipRef),
                );
              },
            },
          });

          if (triggeredFrom == 'Worker_Modal') {
            logEvent(
              kind === RelationshipKindEnum.FAVORITED
                ? GAEvent.Worker_Unfavorite_From_Modal
                : GAEvent.Worker_Unblocked_From_Modal,
              worker.id,
            );
          } else if (triggeredFrom == 'Job_Detail_Page') {
            logEvent(
              kind === RelationshipKindEnum.FAVORITED
                ? GAEvent.Worker_Unfavorite_From_Job_Detail_Page
                : GAEvent.Worker_Unblocked_From_Job_Detail_Page,
              worker.id,
            );
          }
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const relationshipStatus = React.useCallback(
    (worker): RelationshipKindEnum | null => {
      const workerRelationships = job?.account?.customer?.workerRelationships;
      const favorite = workerRelationships.some(
        (r) =>
          r.worker.id === worker.id &&
          r.kind === RelationshipKindEnum.FAVORITED,
      );
      const blocked = workerRelationships.some(
        (r) =>
          r.worker.id === worker.id && r.kind === RelationshipKindEnum.BLOCKED,
      );
      if (favorite) {
        return RelationshipKindEnum.FAVORITED;
      } else if (blocked) {
        return RelationshipKindEnum.BLOCKED;
      }
      return null;
    },
    [job],
  );

  const createWorkerRelationship = async (
    worker,
    reason = '',
    comments = '',
    kind = RelationshipKindEnum.FAVORITED,
    triggeredFrom: string = '',
  ) => {
    try {
      //if worker is favorited and trying to block, remove favorite
      if (
        relationshipStatus(worker) === RelationshipKindEnum.FAVORITED &&
        kind === RelationshipKindEnum.BLOCKED
      ) {
        await deleteWorkerRelationship(
          worker,
          RelationshipKindEnum.FAVORITED,
          triggeredFrom,
        );
      }

      await createRelationship({
        variables: {
          customerId: job.account.customer.id,
          workerId: worker.id,
          kind: kind,
          comments: comments,
          reason: reason,
        },
        update: (cache, { data }) => {
          cache.modify({
            id: `Customer:${job.account.customer.id}`,
            fields: {
              workerRelationships(existingRefs = [], { readField }) {
                if (!data) return existingRefs;
                const { workerRelationship } = data.customerRelateWorker;

                if (
                  existingRefs.some(
                    (ref) => readField('id', ref) === workerRelationship.id,
                  )
                ) {
                  return existingRefs;
                }

                const newRelationRef = cache.writeFragment({
                  fragment: WorkerRelationshipItemFragmentDoc,
                  data: workerRelationship,
                });

                return [...existingRefs, newRelationRef];
              },
            },
          });
          if (triggeredFrom == 'Worker_Modal') {
            logEvent(
              kind === RelationshipKindEnum.FAVORITED
                ? GAEvent.Worker_Favorited_From_Modal
                : GAEvent.Worker_Blocked_From_Modal,
              worker.id,
            );
          } else if (triggeredFrom == 'Job_Detail_Page') {
            logEvent(
              kind === RelationshipKindEnum.FAVORITED
                ? GAEvent.Worker_Favorited_From_Job_Detail_Page
                : GAEvent.Worker_Blocked_From_Job_Detail_Page,
              worker.id,
            );
          }
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getDisableRowForInvited = (invited) => {
    return (
      <DisableRow
        key={invited.id}
        StatusChip={InvitedChip}
        invited={invited}
        isClientAdmin={isClientAdmin}
        isDropped={false}
        isNoShow={false}
        job={job}
        workerStatus="Invited"
        relationshipStatus={relationshipStatus(invited.worker)}
        createWorkerRelationship={(reason, comments, kind) =>
          createWorkerRelationship(
            invited.worker,
            reason,
            comments,
            kind,
            'Job_Detail_Page',
          )
        }
        deleteWorkerRelationship={(kind) =>
          deleteWorkerRelationship(invited.worker, kind, 'Job_Detail_Page')
        }
      />
    );
  };

  const getDisableRowForDropped = (dropped) => {
    return (
      <DisableRow
        key={dropped.id}
        StatusChip={DroppedChip}
        invited={dropped}
        isClientAdmin={isClientAdmin}
        isDropped={true}
        isNoShow={false}
        job={job}
        workerStatus="Dropped"
        relationshipStatus={relationshipStatus(dropped.worker)}
        createWorkerRelationship={(reason, comments, kind) =>
          createWorkerRelationship(
            dropped.worker,
            reason,
            comments,
            kind,
            'Job_Detail_Page',
          )
        }
        deleteWorkerRelationship={(kind) =>
          deleteWorkerRelationship(dropped.worker, kind, 'Job_Detail_Page')
        }
      />
    );
  };

  const getDisableRowForDeclined = (declined) => {
    return (
      <DisableRow
        key={declined.id}
        StatusChip={DeclinedChip}
        invited={declined}
        isClientAdmin={isClientAdmin}
        isDropped={false}
        isNoShow={false}
        job={job}
        workerStatus="Declined"
        relationshipStatus={relationshipStatus(declined.worker)}
        createWorkerRelationship={(reason, comments, kind) =>
          createWorkerRelationship(
            declined.worker,
            reason,
            comments,
            kind,
            'Job_Detail_Page',
          )
        }
        deleteWorkerRelationship={(kind) =>
          deleteWorkerRelationship(declined.worker, kind, 'Job_Detail_Page')
        }
      />
    );
  };

  const getHiredRow = (worker) => {
    return (
      <HiredWorkerRow
        key={worker.id}
        checkboxIds={checkboxIds}
        isClientAdmin={isClientAdmin}
        isSelecting={isSelecting}
        job={job}
        jobStatus={jobStatus}
        setCheckboxIds={setCheckboxIds}
        setIsSelecting={setIsSelecting}
        worker={worker}
        relationshipStatus={relationshipStatus(worker.worker)}
        createWorkerRelationship={(reason, comments, kind) =>
          createWorkerRelationship(
            worker.worker,
            reason,
            comments,
            kind,
            'Job_Detail_Page',
          )
        }
        deleteWorkerRelationship={(kind) =>
          deleteWorkerRelationship(worker.worker, kind, 'Job_Detail_Page')
        }
      />
    );
  };

  const getHiredRowForRemovedWorker = (worker) => {
    return (
      <HiredWorkerRow
        key={worker.id}
        checkboxIds={checkboxIds}
        isClientAdmin={isClientAdmin}
        job={job}
        jobStatus={jobStatus}
        setCheckboxIds={setCheckboxIds}
        worker={worker}
        relationshipStatus={relationshipStatus(worker.worker)}
        createWorkerRelationship={(reason, comments, kind) =>
          createWorkerRelationship(
            worker.worker,
            reason,
            comments,
            kind,
            'Job_Detail_Page',
          )
        }
        deleteWorkerRelationship={(kind) =>
          deleteWorkerRelationship(worker.worker, kind, 'Job_Detail_Page')
        }
      />
    );
  };

  const getDisableRowForRemoved = (removed) => {
    return (
      <DisableRow
        key={removed.id}
        StatusChip={RemovedWorkerChip}
        invited={removed}
        isClientAdmin={isClientAdmin}
        isDropped={false}
        isNoShow={false}
        job={job}
        workerStatus="Removed"
        relationshipStatus={relationshipStatus(removed.worker)}
        createWorkerRelationship={(reason, comments, kind) =>
          createWorkerRelationship(
            removed.worker,
            reason,
            comments,
            kind,
            'Job_Detail_Page',
          )
        }
        deleteWorkerRelationship={(kind) =>
          deleteWorkerRelationship(removed.worker, kind, 'Job_Detail_Page')
        }
      />
    );
  };

  return (
    <>
      {phoneOnly ? (
        <>
          {!(checkboxIds.length < 0 || isSelecting) && (
            <Stack justify={'apart'} style={{ padding: '20px 0 0' }}>
              <CountPill>
                <CrossHairIcon
                  style={{
                    height: '18px',
                    width: '18px',
                  }}
                  titleAccess="Under Staffed"
                />
                <Text as="span" size="sm" weight="normal">
                  <Text
                    color={
                      hiredWorkers.length < job.quantity ? 'danger' : 'default'
                    }
                    weight={
                      hiredWorkers.length < job.quantity ? 'semibold' : 'normal'
                    }
                  >
                    {' '}
                    {hiredWorkers.length}
                  </Text>
                  /<Text>{job.quantity} </Text>
                  hired
                </Text>
              </CountPill>
              <Stack justify="end">
                <PermissionComponent id="staff-job-btn">
                  <Button
                    a11yLabel={'Staff Job'}
                    disabled={!showHireOption()}
                    id="staff-job-btn"
                    label="Staff Job"
                    style={{
                      padding: '4px 12px',
                      fontSize: '11px',
                      height: '28px',
                    }}
                    type="button"
                    onClick={() => setShowStaffingModel(true)}
                  />
                </PermissionComponent>
                <IconButtonMenu onClick={handleOpenModal}>
                  <Icon icon={faEllipsisV} style={{ color: '#808b9d' }} />
                </IconButtonMenu>
              </Stack>
            </Stack>
          )}
          <Stack
            gap={'0'}
            style={{
              width: 'calc(100% + 28px)',
            }}
          >
            <ActionTab
              checkboxIds={checkboxIds}
              isClientAdmin={isClientAdmin}
              job={job}
              setCheckboxIds={setCheckboxIds}
            />
          </Stack>
          <div style={{ marginTop: '0', width: '100%' }}>
            <Stack>
              {/* Cards Listing Start */}
              <ProfileCardWrap>
                {hiredWorkers.length > 0 &&
                  hiredWorkers.map((w) => (
                    <ProfileCard key={w.id}>{getHiredRow(w)}</ProfileCard>
                  ))}
                {pendingInv.length > 0 &&
                  pendingInv.map((p) => (
                    <ProfileCard key={p.id}>
                      {getDisableRowForInvited(p)}
                    </ProfileCard>
                  ))}
                {droppedWorkers.length > 0 &&
                  droppedWorkers.map((d) => (
                    <ProfileCard key={d.id}>
                      {getDisableRowForDropped(d)}
                    </ProfileCard>
                  ))}
                {removedWorkers.length > 0 &&
                  removedWorkers.map((w) =>
                    containsTimesheet ? (
                      <ProfileCard key={w.id}>
                        {getHiredRowForRemovedWorker(w)}
                      </ProfileCard>
                    ) : (
                      <ProfileCard key={w.id}>
                        {getDisableRowForRemoved(w)}
                      </ProfileCard>
                    ),
                  )}
                {declinedInv.length > 0 &&
                  declinedInv.map((dec) => (
                    <ProfileCard key={dec.id}>
                      {getDisableRowForDeclined(dec)}
                    </ProfileCard>
                  ))}
              </ProfileCardWrap>
              {/* Cards Listing End */}
            </Stack>

            {jobWorkers.length === 0 && jobInvites.length === 0 && (
              <EmptyState
                text={
                  isJobActive
                    ? 'Hire employee for this job by clicking the button above'
                    : 'There were no employees hired for this job'
                }
                title="No hired employees"
              />
            )}
          </div>
        </>
      ) : (
        <Card css={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Stack css={{ padding: '0px 20px' }}>
            <Checkbox
              checked={checkboxIds.length > 0}
              checkedIcon={
                allSelected ? (
                  <CheckBoxOutlined htmlColor="#332F2D" />
                ) : (
                  <IndeterminateCheckBoxOutlined htmlColor="#332F2D" />
                )
              }
              disabled={selectAllDisabled()}
              id="select-all"
              size="small"
              style={{ padding: 0 }}
              onChange={handleSelectAllClick}
            />
            <div
              style={{
                minWidth: '80px',
                color: selectAllDisabled() ? '#AAA' : '#262626',
              }}
            >
              Select All
            </div>
            {checkboxIds.length === 0 ? (
              <Stack css={{ padding: '0px 20px' }} justify={'apart'}>
                <CountPill>
                  <CrossHairIcon
                    style={{
                      height: '18px',
                      width: '18px',
                    }}
                    titleAccess="Under Staffed"
                  />
                  <Text as="span" size="sm" weight="normal">
                    <Text
                      color={
                        hiredWorkers.length < job.quantity
                          ? 'danger'
                          : 'default'
                      }
                      weight={
                        hiredWorkers.length < job.quantity
                          ? 'semibold'
                          : 'normal'
                      }
                    >
                      {' '}
                      {hiredWorkers.length}
                    </Text>
                    <Text>{` / ${job.quantity} hired`}</Text>
                  </Text>
                </CountPill>
                <Stack justify="end">
                  {!hiredWorkers || hiredWorkers.length === 0 ? (
                    <PrintIcon style={{ fontSize: '24px', color: '#AAA' }} />
                  ) : (
                    <PrintButton
                      a11yLabel="Print GravyWorker Timesheet to this job"
                      as="a"
                      disabled={!hiredWorkers || hiredWorkers.length === 0}
                      download={`${job.account.customer.name}_${
                        job.skill.name
                      }_${format(new Date(job.firstShiftStartAt), 'MMddyyyy')}`}
                      href={instance.url || ''}
                      isLoading={instance.loading}
                    >
                      <PrintIcon
                        style={{ fontSize: '24px', color: '#262626' }}
                      />
                    </PrintButton>
                  )}
                  <PermissionComponent id="staff-job-btn">
                    <Button
                      a11yLabel={'Staff Job'}
                      css={{ marginLeft: '20px', height: '36px' }}
                      disabled={!showHireOption()}
                      id="staff-job-btn"
                      label="Staff Job"
                      type="button"
                      onClick={() => setShowStaffingModel(true)}
                    />
                  </PermissionComponent>
                </Stack>
              </Stack>
            ) : (
              <ActionTab
                checkboxIds={checkboxIds}
                isClientAdmin={isClientAdmin}
                job={job}
                setCheckboxIds={setCheckboxIds}
              />
            )}
          </Stack>
          <Table spacing>
            <tbody>
              {hiredWorkers.length > 0 &&
                hiredWorkers.map((w) => getHiredRow(w))}
              {pendingInv.length > 0 &&
                pendingInv.map((p) => getDisableRowForInvited(p))}
              {droppedWorkers.length > 0 &&
                droppedWorkers.map((d) => getDisableRowForDropped(d))}
              {removedWorkers.length > 0 &&
                removedWorkers.map((w) =>
                  containsTimesheet
                    ? getHiredRowForRemovedWorker(w)
                    : getDisableRowForRemoved(w),
                )}

              {declinedInv.length > 0 &&
                declinedInv.map((dec) => getDisableRowForDeclined(dec))}
            </tbody>
          </Table>
          {jobWorkers.length === 0 && jobInvites.length === 0 && (
            <EmptyState
              text={
                isJobActive
                  ? 'Hire employee for this job by clicking the button above'
                  : 'There were no employees hired for this job'
              }
              title="No hired employees"
            />
          )}
        </Card>
      )}
      {showStaffingModel && (
        <WorkerStaffingModel
          customerId={job.account.customer.id}
          hideModal={() => {
            if (refetchJob) {
              refetchJob();
            }
            setShowStaffingModel(false);
          }}
          jobTaxType={job.account.customer.jobTaxType!}
          selectedJobs={[job]}
          showShiftList={false}
        />
      )}
      {modalOpen && (
        <Dialog
          PaperProps={{
            style: {
              height: 'fit-content',
              borderRadius: '15px',
            },
          }}
          aria-labelledby="options-dialog"
          fullScreen={true}
          open={true}
          sx={{
            '& .MuiDialog-container': {
              alignItems: 'end',
              marginBottom: '2px',
            },
          }}
          onClose={handleCloseModal}
        >
          <HeaderDialog>
            <TitleDialog>{job.skill.name}</TitleDialog>
            <IconButton aria-label="close" onClick={handleCloseModal}>
              <CloseOutlined />
            </IconButton>
          </HeaderDialog>
          <DialogContent style={{ padding: '25px 25px 55px' }}>
            <MenuList>
              <MenuItemRight
                disabled={selectAllDisabled()}
                onClick={() => {
                  if (!selectAllDisabled()) {
                    handleSelectAllClick({ target: { checked: true } } as any);
                  }
                  handleCloseModal();
                }}
              >
                <SelectAllIcon style={{ height: '16px', width: '16px' }} />
                Select All
              </MenuItemRight>
              {hiredWorkers && hiredWorkers.length > 0 && (
                <MenuItemRight
                  disabled={!isWeb()}
                  onClick={() => {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = instance.url || '';
                    downloadLink.download = `${job.account.customer.name}_${
                      job.skill.name
                    }_${format(new Date(job.firstShiftStartAt), 'MMddyyyy')}`;
                    downloadLink.click();
                    setTimeout(() => {
                      handleCloseModal();
                    }, 200);
                  }}
                >
                  <Icon icon={faPrint} />
                  Print Timesheet
                </MenuItemRight>
              )}
            </MenuList>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default WorkerTable;
