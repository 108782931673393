import { ApolloError } from '@apollo/client';
import {
  faCheck,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Stack, IconButton } from '@mui/material';
import {
  PickerChangeHandlerContext,
  TimeValidationError,
} from '@mui/x-date-pickers';
import { addDays, format, isAfter, parse, parseISO } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TimesheetItem } from '../../TimesheetList';
import { getTimesheetReport } from '../../util';

import { TickIcon } from '@/assets/icons';
import EditTimeIcon from '@/assets/icons/EditTimeIcon';
import Alert from '@/components/Alert';
import { SubmitErrorAlertProps } from '@/components/Alerts';
import IconicButton from '@/components/IconicButton';
import ShiftTimePicker from '@/components/ShiftTimePicker';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import Form from '@/form';
import TextField from '@/form/TextField';
import {
  GetJobDocument,
  useCreateTimesheetMutation,
  useSaveTimesheetMutation,
} from '@/graphql';
import PermissionComponent from '@/routes/PermissionComponent';
import styled from '@/styles';
import { Maybe } from '@/types';
import { GetJobQuery, Scalars } from '@/types/graphql';
import { getFormattedDateInTimeZone, isDateInvalid } from '@/util/date';
import { handleGraphQLError } from '@/util/error';

interface InlineTimesheetViewProps {
  timesheet?: TimesheetItem;
  allowEdit?: boolean;
  job: GetJobQuery['job'];
  workerInfo: any;
  setInlineEditing: (value: boolean) => void;
  totalTime?: string | number;
  phoneOnly: boolean;
}

type InlineTimesheetFormattedFormValues = {
  timesheetId: string;
  breakMinutes: number;
  checkinAt: string;
  checkoutAt: string;
};

const TimeLabel = styled('span', {
  fontSize: '10px',
  fontWeight: '400',
  color: '#01483D',
  textTransform: 'uppercase',
});

const Time = styled('span', {
  fontSize: '16px',
  fontWeight: '700',
  color: '#01483D',
});

const TimeInput = {
  borderColor: '#FFF',
  borderRadius: '0px',
  borderBottom: '1px solid #44A735',
  width: '90px',
};

const TimeInputText = {
  padding: '4px 2px',
  fontWeight: 700,
  fontSize: '16px !important',
  color: '#01483D',
};

const TextInput = {
  borderRadius: '0px',
  borderBottom: '1px solid #44A735',
  padding: '0px',
  height: '40px',
  fontWeight: 700,
  fontSize: '16px',
  color: '#01483D',
};

type InlineFormValues = {
  approvedBreakMinutes: string;
  approvedCheckinAt: string;
  approvedCheckoutAt: string;
};

const InlineTimesheetView = (props: InlineTimesheetViewProps) => {
  const {
    timesheet,
    job,
    workerInfo,
    allowEdit = true,
    setInlineEditing,
    totalTime,
    phoneOnly,
  } = props;
  const shift = job.shifts[0];

  const initialValues: InlineFormValues = useMemo((): InlineFormValues => {
    const _checkIn = getTimesheetReport(
      timesheet?.approvedCheckinAt || null,
      timesheet?.reportedCheckinAt || null,
      timesheet?.checkinAt || null,
    );
    const _checkOut = getTimesheetReport(
      timesheet?.approvedCheckoutAt || null,
      timesheet?.reportedCheckoutAt || null,
      timesheet?.checkoutAt || null,
    );
    return {
      approvedBreakMinutes: timesheet?.reportedBreakMinutes ?? '',
      approvedCheckinAt: _checkIn ? format(_checkIn, 'HH:mm') : '',
      approvedCheckoutAt: _checkOut ? format(_checkOut, 'HH:mm') : '',
    };
  }, [timesheet]);

  const [isEditing, setIsEditing] = useState(false);
  const [formValues, setFormValues] = useState<InlineFormValues>(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  const [submitError, setSubmitError] =
    useState<Maybe<SubmitErrorAlertProps>>(null);

  useEffect(() => {
    if (isEditing && !allowEdit) {
      setIsEditing(false);
      setInlineEditing(false);
      setSubmitError(null);
    }
  }, [allowEdit]);

  const handleOnErrorTimesheetMutation = (error: ApolloError) => {
    handleGraphQLError(error, {
      INVALID_DATES: () => {
        setSubmitError({
          title: 'Invalid shift times',
          description: 'description TEST',
        });
      },
      WORKING_TIME_TOO_SHORT: ({ message }) => {
        setSubmitError({
          title: 'Approved time must be longer',
          description: message,
        });
      },
      BREAK_TIME_TOO_LONG: ({ message }) => {
        setSubmitError({
          title: 'Break time must be short',
          description: message,
        });
      },
      SHIFT_TOO_LONG: () => {
        setSubmitError({
          title: 'Shift length too long',
          description: 'Shifts cannot exceed 24 hours.',
        });
      },
      all: () => {
        setSubmitError({
          description: UNKNOWN_ERROR_TEXT,
        });
      },
    });
  };

  const handleSaveTimesheet = async (
    timesheetFormattedFormValues: InlineTimesheetFormattedFormValues,
  ) => {
    const { checkinAt, checkoutAt, breakMinutes, timesheetId } =
      timesheetFormattedFormValues;

    const timesheetPayload = {
      timesheetId,
      approvedCheckinAt: checkinAt,
      approvedCheckoutAt: checkoutAt,
      approvedBreakMinutes: breakMinutes,
      tipAmount: timesheet?.tipAmount || 0,
      ratingComment: '',
    };

    await saveInlineTimesheet({
      variables: timesheetPayload,
    });
  };

  const [saveInlineTimesheet] = useSaveTimesheetMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId: job.id } }],
    onCompleted: () => {
      setIsEditing(false);
      setInlineEditing(false);
    },
    onError: handleOnErrorTimesheetMutation,
  });

  const [createInlineTimesheet] = useCreateTimesheetMutation({
    onError: handleOnErrorTimesheetMutation,
  });

  const checkIn = getTimesheetReport(
    timesheet?.approvedCheckinAt || null,
    timesheet?.reportedCheckinAt || null,
    timesheet?.checkinAt || null,
    job.address.timezone,
  );
  const checkOut = getTimesheetReport(
    timesheet?.approvedCheckoutAt || null,
    timesheet?.reportedCheckoutAt || null,
    timesheet?.checkoutAt || null,
    job.address.timezone,
  );

  const editTimesheet = () => {
    setInlineEditing(!isEditing);
    setIsEditing((isEditing) => !isEditing);
  };

  const getParsedDateRange = (
    approvedCheckinAt: Scalars['String'],
    approvedCheckoutAt: Scalars['String'],
    shiftStartAt: string,
  ) => {
    const startAt = parse(approvedCheckinAt, 'HH:mm', parseISO(shiftStartAt));
    const endAt = parse(approvedCheckoutAt, 'HH:mm', parseISO(shiftStartAt));

    return { startAt, endAt };
  };

  const handleFormValuesChange = (
    fieldContext,
    fieldId: keyof InlineFormValues,
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleTimeChange = (
    time: Dayjs,
    event: PickerChangeHandlerContext<TimeValidationError>,
    key: string,
  ) => {
    const value = time ? time?.format('HH:mm') : '';
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = useCallback(
    async ({ approvedBreakMinutes }: InlineFormValues) => {
      const { approvedCheckinAt, approvedCheckoutAt } = formValues;
      setSubmitError(null);

      if (
        approvedCheckinAt === '' &&
        approvedCheckoutAt === '' &&
        approvedBreakMinutes === ''
      ) {
        setIsEditing(false);
        setInlineEditing(false);
        return;
      }

      let timesheetId = timesheet?.id;

      let { startAt, endAt } = getParsedDateRange(
        approvedCheckinAt,
        approvedCheckoutAt,
        timesheet?.shift.startAt || shift.startAt,
      );

      if (isDateInvalid(startAt) || isDateInvalid(endAt)) {
        const timeLabel = isDateInvalid(startAt)
          ? 'a start time'
          : 'an end time';

        setSubmitError({
          title: 'Shift times are invalid',
          description: `Enter ${timeLabel} to approve.`,
        });
        return;
      }

      if (isAfter(startAt, endAt)) {
        endAt = addDays(endAt, 1);
      }

      if (!timesheetId) {
        const createResp = await createInlineTimesheet({
          variables: {
            jobId: job.id,
            workerId: workerInfo.id,
          },
        });

        if (createResp?.data?.timesheetCreate.timesheet.id) {
          timesheetId = createResp?.data?.timesheetCreate.timesheet.id;
        } else {
          return;
        }
      }

      const timesheetFormattedFormValues: InlineTimesheetFormattedFormValues = {
        timesheetId,
        breakMinutes: Number(approvedBreakMinutes),
        checkinAt: getFormattedDateInTimeZone(
          startAt,
          job.address.timezone,
        ).toISOString(),
        checkoutAt: getFormattedDateInTimeZone(
          endAt,
          job.address.timezone,
        ).toISOString(),
      };

      await handleSaveTimesheet(timesheetFormattedFormValues);
    },
    [formValues, timesheet?.id],
  );

  return (
    <Stack gap={0.5}>
      {submitError && (
        <Alert
          description={submitError.description}
          icon={faExclamationTriangle}
          status={'warning'}
          style={{ padding: '10px' }}
        />
      )}
      <Form
        data-testid="inline-timesheet-form"
        id="inline-timesheet-form"
        initialValues={formValues}
        onSubmit={handleSubmit}
      >
        <Stack
          alignItems={'center'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Stack
            flexDirection={'row'}
            gap={isEditing ? '10px' : '30px'}
            padding={'4px'}
          >
            <Stack>
              <TimeLabel>check In</TimeLabel>
              {!isEditing ? (
                <Time>
                  {(checkIn && format(checkIn, 'p')) == null
                    ? '-'
                    : checkIn && format(checkIn, 'p')}
                </Time>
              ) : (
                <ShiftTimePicker
                  defaultValue={dayjs(
                    checkIn ? format(checkIn, 'HH:mm') : '',
                    'HH:mm',
                  )}
                  fix={!phoneOnly}
                  inputStyle={{
                    '& fieldset': {
                      ...TimeInput,
                    },
                    '& input': {
                      ...TimeInputText,
                    },
                  }}
                  label="00:00 AM"
                  name="approvedCheckinAt"
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  onChange={handleTimeChange}
                />
              )}
            </Stack>

            <Stack>
              <TimeLabel>check Out</TimeLabel>
              {!isEditing ? (
                <Time>
                  {(checkOut && format(checkOut, 'p')) == null
                    ? '-'
                    : checkOut && format(checkOut, 'p')}
                </Time>
              ) : (
                <ShiftTimePicker
                  defaultValue={dayjs(
                    checkOut ? format(checkOut, 'HH:mm') : '',
                    'HH:mm',
                  )}
                  fix={!phoneOnly}
                  inputStyle={{
                    '& fieldset': {
                      ...TimeInput,
                    },
                    '& input': {
                      ...TimeInputText,
                    },
                  }}
                  label="00:00 PM"
                  name="approvedCheckoutAt"
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  onChange={handleTimeChange}
                />
              )}
            </Stack>
            <Stack>
              <TimeLabel>Break</TimeLabel>
              <Time>
                {!isEditing ? (
                  timesheet && checkOut ? (
                    (timesheet.approvedBreakMinutes ||
                      timesheet.reportedBreakMinutes) + ' Min'
                  ) : (
                    '-'
                  )
                ) : (
                  <TextField
                    callback={(fieldContext) =>
                      handleFormValuesChange(
                        fieldContext,
                        'approvedBreakMinutes',
                      )
                    }
                    displayType="block"
                    fieldId="approvedBreakMinutes"
                    max="90"
                    min="0"
                    placeholder="0 Min"
                    showFocusCover={false}
                    style={TextInput}
                    type="number"
                    width="60px"
                  />
                )}
              </Time>
            </Stack>
            {!isEditing && totalTime ? (
              <Stack>
                <TimeLabel>TOTAL</TimeLabel>
                <Time>{totalTime}</Time>
              </Stack>
            ) : null}
          </Stack>

          <Stack
            flexDirection={'row'}
            gap={'0'}
            padding={'0'}
            style={{ marginLeft: '10px' }}
          >
            {isEditing ? (
              <IconicButton
                a11yLabel="Save Timesheet"
                appearance="clear"
                customIcon={
                  <TickIcon
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                }
                data-testid="save-timesheet-btn"
                icon={faCheck}
                id="save-timesheet-btn"
                size="sm"
                type="submit"
              />
            ) : (
              <PermissionComponent id="edit-timesheet-btn">
                <IconButton
                  data-testid="edit-timesheet-btn"
                  disabled={!allowEdit}
                  id="edit-timesheet-btn"
                  style={{ padding: '1px' }}
                  onClick={editTimesheet}
                >
                  <EditTimeIcon
                    style={{
                      cursor: 'pointer',
                      opacity: allowEdit ? 1.0 : 0.5,
                    }}
                  />
                </IconButton>
              </PermissionComponent>
            )}
          </Stack>
        </Stack>
      </Form>
    </Stack>
  );
};

export default InlineTimesheetView;
