import React from 'react';
import styled from '@/styles';
import Card from '@/components/Card';
import Modal from '@/components/Modal';

interface MobileTooltipModalProps {
  title: string;
  content: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

const ModalContent = styled('div', {
  padding: '20px',
  fontSize: '16px',
  lineHeight: '1.5',
  color: '#333',
});

const MobileTooltipModal: React.FC<MobileTooltipModalProps> = ({
  title,
  content,
  onClose,
}) => {
  return (
    <Modal
      size="fullWidth"
      title={title}
      onRequestClose={onClose}
      wrapperBackground={true}
      disableClickout={true}
      displayBottom={true}
    >
      <Card>
        <Card.Section>
          <ModalContent>{content}</ModalContent>
        </Card.Section>
      </Card>
    </Modal>
  );
};

export default MobileTooltipModal;
