import React, { useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styled from '@/styles';
import Tooltip from '@/components/Tooltip';
import Icon from '@/elements/Icon';
import MobileTooltipModal from '@/components/MobileTooltipModal';
import { Capacitor } from '@capacitor/core';
import { useModal } from 'react-modal-hook';

interface MobileTooltipInfoProps {
  text: string;
  title?: string;
}

const InfoIconWrapper = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

const MobileTooltipInfo: React.FC<MobileTooltipInfoProps> = ({
  text,
  title = 'Detail',
}) => {
  // Check if running on iOS or Android through Capacitor
  const isMobile =
    Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android';

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isMobile) {
      showConfirmationModal();
    }
  };

  const [showConfirmationModal, hideConfirmationModal] = useModal(
    () => (
      <MobileTooltipModal
        title={title}
        content={text}
        onClose={hideConfirmationModal}
      />
    ),
    [],
  );

  return (
    <>
      <InfoIconWrapper onClick={handleClick}>
        {isMobile ? (
          <Icon icon={faInfoCircle} theme="lighter" />
        ) : (
          <Tooltip align="aboveRight" text={text}>
            <Icon icon={faInfoCircle} theme="lighter" />
          </Tooltip>
        )}
      </InfoIconWrapper>
    </>
  );
};

export default MobileTooltipInfo;
