import React from 'react';
import styled from '@/styles';

interface StatusBadgeProps {
  label: string;
  variant?: 'disabled' | 'active' | 'pending' | 'custom';
  backgroundColor?: string;
  textColor?: string;
  className?: string;
}

const StyledBadge = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
  padding: '4px 12px',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '1.5',
  textTransform: 'uppercase',
  letterSpacing: '0.5px',

  variants: {
    variant: {
      disabled: {
        backgroundColor: '#ffd7d7',
        color: '#d32f2f',
      },
      active: {
        backgroundColor: '#e8f5e9',
        color: '#2e7d32',
      },
      pending: {
        backgroundColor: '#fff8e1',
        color: '#f57f17',
      },
      custom: {
        // Colors will be set via props
      },
    },
  },
});

const StatusBadge: React.FC<StatusBadgeProps> = ({
  label,
  variant = 'disabled',
  backgroundColor,
  textColor,
  className,
}) => {
  const customStyle =
    variant === 'custom'
      ? {
          backgroundColor,
          color: textColor,
        }
      : {};

  return (
    <StyledBadge variant={variant} className={className} style={customStyle}>
      {label}
    </StyledBadge>
  );
};

export default StatusBadge;
