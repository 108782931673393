import styled from '@/styles';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';

const Message = styled('p', {
  fontSize: '16px',
  lineHeight: '1.5',
  marginBottom: '16px',
  color: '#333',
  fontWeight: 'bold',
});

const SubMessage = styled('p', {
  fontSize: '14px',
  lineHeight: '1.5',
  marginBottom: '24px',
  color: '#666',
});

const ConfirmationModal = ({
  title,
  message,
  subMessage,
  onConfirm,
  onCancel,
  confirmLabel = 'Save',
  cancelLabel = 'Cancel',
}) => {
  return (
    <Modal
      displayFlex
      size="xs"
      title={title}
      onRequestClose={onCancel}
      wrapperBackground={true}
    >
      <Card>
        <Card.Section>
          <Message>{message}</Message>
          {subMessage && <SubMessage>{subMessage}</SubMessage>}

          <Stack justify="end" gap={12}>
            <Button
              a11yLabel={cancelLabel}
              appearance="outline"
              label={cancelLabel}
              onClick={onCancel}
            />
            <Button
              a11yLabel={confirmLabel}
              appearance="primary"
              label={confirmLabel}
              onClick={onConfirm}
            />
          </Stack>
        </Card.Section>
      </Card>
    </Modal>
  );
};

export default ConfirmationModal;
